:root {
    --main-color: #777;
    --main-bg: #E8E9EF;

    --primary-bg: #000;
    --secondary-bg: #FAFAFA;
    --tertiary-bg: #222;
	
    --accent-color: #7342FF;
    --accent2-color: #512DB8;
	--accent3-color: #BA3030;
	--accent4-color: #AC8359;
    
	--primary-color: #000;
    --secondary-color: #FAFAFA;
    --tertiary-color: #fff;
    --quarter-color: #DDDDDD;
    --fifth-color: #94B5AD;
    --sixth-color: #E9E7F0;
    
    --main-border-color: #222;
    --secondary-border-color: #333;
    --tertiary-border-color: #C1C1BF;
	--quarter-border-color: #444;

    --main-font-family: "Plus Jakarta Sans", sans-serif;
    --primary-font-family: "Alata", sans-serif;

    --button-bg: linear-gradient(270deg, #3D5AFF -0.13%, #7B3EFF 53.94%, #7D3CFB 67.96%, #8739F0 80.97%, #9634DF 91.99%, #A52FCE 100%);
    --button-color: #FAFAFA;
    --button-bg-hover: linear-gradient(270deg, #506AFF -0.13%, #844BFF 53.94%, #864BFA 67.96%, #914DEE 80.97%, #9E46E0 91.99%, #AA40CF 100%);
    --button-color-hover: #FAFAFA;
    --button2-bg: #FAFAFA;
    --button2-color: #02654D;
    --button2-bg-hover: linear-gradient(270deg, #506AFF -0.13%, #844BFF 53.94%, #864BFA 67.96%, #914DEE 80.97%, #9E46E0 91.99%, #AA40CF 100%);
    --button2-color-hover: #FAFAFA;
    // //--button3-bg: #111111;
    --button3-bg: transparent;
    --button3-color: #DDDDDD;
    // //--button3-bg-hover: #111111;
    --button3-bg-hover: transparent;
    --button3-color-hover: #FAFAFA;
}
