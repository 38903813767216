$site-domain: "";
@import "variables";
@import "button";
@import "ui";
@import "fonts";
@import "slick-carousel/slick/slick.css";
@import "aos/dist/aos.css";

.spinner-h {
  position: fixed;
  background: transparent;
  background: #fbf9f7;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
  transition: all 0.5s;
}

.box {
  position: relative;
  background: var(--secondary-bg);
  color: var(--main-color);
  margin: 0 0 3rem;

  .box-heading {
    padding: .5rem 2rem;
  }

  .box-content {
    padding: 2rem;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .box-footer {
    padding: .5rem 2rem;
  }
}


.error__page {
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.8rem;
  }

  h1 {
    font-size: 6rem;
    line-height: 140%;
    font-weight: 400;
    margin-bottom: 3rem;
  }
}

.error__page-content {
  padding: 0 0 5rem;

  & > img,
  & > svg {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 2rem;
  }

  *:last-child {
    margin-bottom: 0;
  }
}

.page-notfound-content {
  padding: 0 0 5rem;

  & > img,
  & > svg {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 2rem;
  }

  *:last-child {
    margin-bottom: 0;
  }
}


/* contactus */
.contactus__page main {
  padding-bottom: 0;
}

.contactus-details {
  padding: 1rem 0 2.5rem;

  .items {
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 3rem;

    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      padding: 5rem;
      font-size: 1.8rem;
      line-height: 170%;
      margin-bottom: 0;

      *[class*=icon-] {
        display: inline-block;
        vertical-align: top;
        font-size: 7rem;
        line-height: 7rem;
        width: 7rem;
        height: 7rem;
        text-align: center;
        color: var(--primary-color);
      }

      > * {
        margin-bottom: 0;
      }

      a {
        color: var(--main-color);
        text-decoration: none;

        &:hover {
          color: var(--accent-color);
        }
      }
    }
  }
}

.contactus-items{
	display: flex;
    flex-direction: row;
    gap: 3rem;
	padding-bottom: 2rem;

	.item{
		flex: 1;

		h3{
			margin-bottom: 2.5rem;
		}
	}
}

.contactus-banner {
  border-top: 2rem solid var(--main-border-color);
  background: var(--main-border-color);
  position: relative;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: url(#{$site-domain}/Media/Image/bg-contactus-banner.jpg) 50% 50% / cover;
  }

  .main-holder {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 49rem;
    text-align: center;
    color: var(--secondary-color);
    position: relative;
    z-index: 1;

    .text {
      *[class*=icon-] {
        display: inline-block;
        vertical-align: top;
        font-size: 7rem;
        margin-bottom: 4rem;
      }

      h2 {
        color: var(--secondary-color);
        margin-bottom: 2.4rem;
      }
    }

    .social a {
      color: var(--secondary-color);

      &:hover {
        color: var(--accent-color);
      }
    }
  }
}

.rtl-page .contactus-banner:before {
  transform: scaleX(-1);
}


@media only screen and (max-width: 1450px) {
  .contactus-details {
    .items {
      flex-wrap: wrap;
      justify-content: center;

      .item {
        max-width: 45rem;
        width: 100%;
        flex: auto;
      }
    }
  }
  .contactus-items {
	flex-wrap: wrap;

	.item {
		width: 100%;
		flex: auto;
	}
  }
}

@media only screen and (max-width: 1200px) {
  .page-notfound-content {
    flex-direction: column;
    gap: 3rem;
  }
}

@media only screen and (max-width: 991px) {

}

@media only screen and (max-width: 767px) {
  .error__page {
    h1 {
      font-size: 6rem;
      line-height: 140%;
      font-weight: 400;
      margin-bottom: 3rem;
    }
  }

  .contactus-details {
    padding: 1rem 0 4rem;

    .items {
      flex-direction: column;
      gap: 2rem;
      margin-bottom: 1rem;

      .item {
        width: 100%;
        max-width: none;
        padding: 3rem;
        min-height: 28.2rem;
        justify-content: center;
      }
    }
  }

  .contactus-items{
	flex-direction: column;
	gap: .5rem;

	.item {
        width: 100%;
        max-width: none;

		h3{margin-bottom: 3rem;}
	}
  }

  .contactus-banner {
    &:before {
      background: url(#{$site-domain}/Media/Image/bg-contactus-banner-sm.jpg) 50% 50% / cover;
    }

    .main-holder {
      min-height: 44.7rem;
    }

    h2 {
      font-size: 4rem;
    }
  }
}


[class*=content-section] {
  position: relative;
  padding: 8rem 0;
  border-top: 2rem solid var(--main-border-color);

  h2 {
    font-size: 5rem;
    margin-bottom: 4rem;
  }
}

[class*=content-section].bg {
  background: var(--primary-bg);
  color: var(--quarter-color);

  h1, h2, h3 {
    color: var(--quarter-color);
  }
}

.section-heading {
  margin-bottom: 6rem;

  *:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  [class*=content-section] {
    padding: 5rem 0;

    h2 {
      font-size: 3rem;
      margin-bottom: 3rem;
    }
  }
  .section-heading {
    margin-bottom: 4rem;
  }
}

.content-section03 {
  .section-heading {
    max-width: 100rem;
	p{margin-bottom: 4rem;}
  }

  .items {
    display: flex;
    gap: 2rem;

    .item {
      flex: 1;
      padding: 3rem;
      transition: all 0.5s ease;
      margin-bottom: 0;

      > * {
        transition: all 0.5s ease;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h3 {
        font-size: 3rem;
      }

      > *[class*=icon-] {
        display: inline-block;
        vertical-align: top;
        font-size: 7rem;
        margin-bottom: 3rem;
        color: var(--primary-color);
      }

      &:hover {
        background: var(--accent2-color);
        color: var(--sixth-color);

        h3,
        *[class*=icon-] {
          color: var(--secondary-color);
        }
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .content-section03 {
    .items {
      flex-wrap: wrap;
      justify-content: center;

      .item {
        flex: auto;
        width: 100%;
        max-width: 45rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .content-section03 {
	padding: 7rem 0 5rem;
    .section-heading {
      margin-bottom: 4rem;
	  p{margin-bottom: 3.5rem;}
    }

	.text-style01{
		font-size: 1.8rem;
		line-height: 170%;

	}

    .items {
      padding-bottom: 0;
      gap: 2rem;

      .item {
		min-height: 51.5rem;
        padding: 3rem;
        max-width: none;

        > *[class*=icon-] {
          margin-bottom: 2.8rem;
        }
      }
    }
  }
}

.content-section04 {
	.section-heading{
		max-width: 107.6rem;
	}
	
	.items{
		gap: 3rem;
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		margin-bottom: 6rem;

		.item{
			grid-column: span 2;
			padding: 3rem;
			background: var(--tertiary-bg);
			border: 1px solid var(--accent2-color);
			border-width: .4rem 0 0;

			&:nth-child(4),
			&:nth-child(5) {
				grid-column: span 3;
			}
			

			h3{
				font-weight: 500;
				font-family: var(--main-font-family);
				margin-bottom: 2rem;
			}
			> *:last-child{margin-bottom: 0;}
		}
	}
	.section-footer{
		max-width: 107.6rem;
		.btn-hold{
			padding-top: 2rem;
		}
	}
}
@media only screen and (max-width: 767px) {
	.content-section04 {
		.items{
			display: flex;
			flex-direction: column;
			gap: 2rem;
			margin-bottom: 5rem;
			.item{
				padding-top: 2.5rem;
				padding-bottom: 3rem;
			}
		}
		.section-footer{
			font-size: 1.8rem;
			line-height: 170%;
		}
	}
}


.payment {
  background: var(--seventh-bg);
  padding: 2.5rem 0;
  padding: 3.4rem 0;

  .items {
    display: flex;
    gap: 1.8rem 2.4rem;
    justify-content: space-between;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .payment {
	padding: 2.3rem 0;
    .items {
      flex-wrap: wrap;
      justify-content: center;
      gap: 1.5rem 3rem;
    }
  }
}


/*bootstrap fixes*/
@media only screen and (min-width: 575px) {
  .text-xs-start {
    text-align: start !important;
  }

  .text-xs-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 576px) {
  .text-sm-start {
    text-align: start !important;
  }

  .text-sm-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 768px) {
  .text-md-start {
    text-align: start !important;
  }

  .text-md-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 992px) {
  .text-lg-start {
    text-align: start !important;
  }

  .text-lg-end {
    text-align: end !important;
  }
}

@media only screen and (min-width: 1200px) {
  .text-xl-start {
    text-align: start !important;
  }

  .text-xl-end {
    text-align: end !important;
  }
}

/* offsets */
.rtl-page *[class*=offset-] {
  margin-left: 0 !important;
}

@media (min-width: 576px) {
  .rtl-page .offset-sm-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-sm-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-sm-3 {
    margin-right: 25%
  }

  .rtl-page .offset-sm-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-sm-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-sm-6 {
    margin-right: 50%
  }

  .rtl-page .offset-sm-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-sm-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-sm-9 {
    margin-right: 75%
  }

  .rtl-page .offset-sm-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-sm-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 768px) {
  .rtl-page .offset-md-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-md-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-md-3 {
    margin-right: 25%
  }

  .rtl-page .offset-md-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-md-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-md-6 {
    margin-right: 50%
  }

  .rtl-page .offset-md-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-md-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-md-9 {
    margin-right: 75%
  }

  .rtl-page .offset-md-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-md-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 992px) {
  .rtl-page .offset-lg-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-lg-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-lg-3 {
    margin-right: 25%
  }

  .rtl-page .offset-lg-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-lg-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-lg-6 {
    margin-right: 50%
  }

  .rtl-page .offset-lg-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-lg-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-lg-9 {
    margin-right: 75%
  }

  .rtl-page .offset-lg-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-lg-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 1200px) {
  .rtl-page .offset-xl-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-xl-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-xl-3 {
    margin-right: 25%
  }

  .rtl-page .offset-xl-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-xl-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-xl-6 {
    margin-right: 50%
  }

  .rtl-page .offset-xl-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-xl-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-xl-9 {
    margin-right: 75%
  }

  .rtl-page .offset-xl-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-xl-11 {
    margin-right: 91.666667%
  }
}

@media (min-width: 1400px) {
  .rtl-page .offset-xxl-1 {
    margin-right: 8.333333%
  }

  .rtl-page .offset-xxl-2 {
    margin-right: 16.666667%
  }

  .rtl-page .offset-xxl-3 {
    margin-right: 25%
  }

  .rtl-page .offset-xxl-4 {
    margin-right: 33.333333%
  }

  .rtl-page .offset-xxl-5 {
    margin-right: 41.666667%
  }

  .rtl-page .offset-xxl-6 {
    margin-right: 50%
  }

  .rtl-page .offset-xxl-7 {
    margin-right: 58.333333%
  }

  .rtl-page .offset-xxl-8 {
    margin-right: 66.666667%
  }

  .rtl-page .offset-xxl-9 {
    margin-right: 75%
  }

  .rtl-page .offset-xxl-10 {
    margin-right: 83.333333%
  }

  .rtl-page .offset-xxl-11 {
    margin-right: 91.666667%
  }
}